export const exampleSketches = [
  {
    original: '/images/examples/example-1.jpg',
    thumbnail: '/images/examples/example-1.jpg',
  },
  {
    original: '/images/examples/example-2.jpg',
    thumbnail: '/images/examples/example-2.jpg',
  },
  {
    original: '/images/examples/example-3.jpg',
    thumbnail: '/images/examples/example-3.jpg',
  },
  {
    original: '/images/examples/example-4.jpg',
    thumbnail: '/images/examples/example-4.jpg',
  },
  {
    original: '/images/examples/example-5.jpg',
    thumbnail: '/images/examples/example-5.jpg',
  },
  {
    original: '/images/examples/example-6.jpg',
    thumbnail: '/images/examples/example-6.jpg',
  },
  {
    original: '/images/examples/example-7.jpg',
    thumbnail: '/images/examples/example-7.jpg',
  },
  {
    original: '/images/examples/example-8.jpg',
    thumbnail: '/images/examples/example-8.jpg',
  },
  {
    original: '/images/examples/example-9.jpg',
    thumbnail: '/images/examples/example-9.jpg',
  },
  {
    original: '/images/examples/example-10.jpg',
    thumbnail: '/images/examples/example-10.jpg',
  },
  {
    original: '/images/examples/example-11.jpg',
    thumbnail: '/images/examples/example-11.jpg',
  },
  {
    original: '/images/examples/example-12.jpg',
    thumbnail: '/images/examples/example-12.jpg',
  },
  {
    original: '/images/examples/example-13.jpg',
    thumbnail: '/images/examples/example-13.jpg',
  },
  {
    original: '/images/examples/example-14.jpg',
    thumbnail: '/images/examples/example-14.jpg',
  },
];

export const exampleSketchesTShirt = [
  {
    original: '/images/lp/t-shirt/example-1.png',
    thumbnail: '/images/lp/t-shirt/example-1.png',
  },
  {
    original: '/images/lp/t-shirt/example-2.png',
    thumbnail: '/images/lp/t-shirt/example-2.png',
  },
  {
    original: '/images/lp/t-shirt/example-3.png',
    thumbnail: '/images/lp/t-shirt/example-3.png',
  },
  {
    original: '/images/lp/t-shirt/example-4.png',
    thumbnail: '/images/lp/t-shirt/example-4.png',
  },
  {
    original: '/images/lp/t-shirt/example-5.png',
    thumbnail: '/images/lp/t-shirt/example-5.png',
  },
  {
    original: '/images/lp/t-shirt/example-6.png',
    thumbnail: '/images/lp/t-shirt/example-6.png',
  },
  {
    original: '/images/lp/t-shirt/example-7.png',
    thumbnail: '/images/lp/t-shirt/example-7.png',
  },
  {
    original: '/images/lp/t-shirt/example-8.png',
    thumbnail: '/images/lp/t-shirt/example-8.png',
  },
  {
    original: '/images/lp/t-shirt/example-9.png',
    thumbnail: '/images/lp/t-shirt/example-9.png',
  },
  {
    original: '/images/lp/t-shirt/example-10.png',
    thumbnail: '/images/lp/t-shirt/example-10.png',
  },
  {
    original: '/images/lp/t-shirt/example-11.png',
    thumbnail: '/images/lp/t-shirt/example-11.png',
  },
  {
    original: '/images/lp/t-shirt/example-12.png',
    thumbnail: '/images/lp/t-shirt/example-12.png',
  },
  {
    original: '/images/lp/t-shirt/example-13.png',
    thumbnail: '/images/lp/t-shirt/example-13.png',
  },
  {
    original: '/images/lp/t-shirt/example-14.png',
    thumbnail: '/images/lp/t-shirt/example-14.png',
  },
];
