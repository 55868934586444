import { Disclosure } from '@headlessui/react';
import React from 'react';
import { FaChevronUp } from 'react-icons/fa';

import Container from '@/components/Container';

export interface FAQItem {
  question: string;
  answer: string;
}

// Update the Faq component to use the FAQData type
const Faq: React.FC<{ faqdata: FAQItem[] }> = ({ faqdata }) => {
  return (
    <Container className='!p-0'>
      <div className='w-full max-w-2xl p-6 mx-auto rounded-2xl'>
        {faqdata.map((item: FAQItem) => (
          <div key={item.question} className='mb-5'>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className='flex items-center justify-between w-full px-4 py-4 text-md sm:text-lg text-left text-gray-800 rounded-lg bg-gray-100 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gold-100 focus-visible:ring-opacity-75 dark:bg-trueGray-700 dark:text-gray-200'>
                    <span>{item.question}</span>
                    <FaChevronUp
                      className={`${
                        open ? 'transform rotate-180' : ''
                      } w-5 h-5 text-gold-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className='px-4 pt-4 pb-2 text-gray-500 dark:text-gray-300'>
                    {item.answer}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Faq;
