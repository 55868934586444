import { Box, Grid } from '@mui/material';
import { styled } from '@mui/system';
import Image from 'next/image';
import React from 'react';

// Remove this import as we'll now receive exampleSketches as a prop
// import { exampleSketches } from '@/constant/LandingPage/exampleSketches';

const ImageContainer = styled('div')(({ theme }) => ({
  borderRadius: '8px',
  overflow: 'hidden',
  cursor: 'pointer',
  width: '200px',
  height: '200px',
  [theme.breakpoints.down('xl')]: {
    width: '180px',
    height: '180px',
  },
  [theme.breakpoints.down('lg')]: {
    width: '150px',
    height: '150px',
  },
  [theme.breakpoints.down('md')]: {
    width: '120px',
    height: '120px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '80px',
    height: '80px',
  },
}));

const StyledImage = styled(Image)({
  objectFit: 'cover',
});

// Define the ExampleSketch type manually
type ExampleSketch = {
  original: string;
  thumbnail: string;
};

// Define the props interface for the ExampleSketches component
interface ExampleSketchesProps {
  exampleSketches: ExampleSketch[];
}

// Update the component to use the props interface
const ExampleSketches: React.FC<ExampleSketchesProps> = ({
  exampleSketches,
}) => {
  return (
    <>
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={2} justifyContent='center'>
          {exampleSketches.map((image, index) => (
            <Grid item key={index}>
              <ImageContainer>
                <StyledImage
                  src={image.original}
                  alt={`Example sketch ${index + 1}`}
                  width={200}
                  height={200}
                  placeholder='blur'
                  blurDataURL='data:image/jpeg;base64,L+P~~BtR_NRPtlaejZofMxays:kC'
                  sizes='(max-width: 320px) 50vw, (max-width: 400px) 33vw, (max-width: 640px) 25vw, 20vw'
                  priority={false}
                  loading='lazy'
                />
              </ImageContainer>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

// Update the export to include the prop type
export default ExampleSketches;
