import { SignUpButton } from '@clerk/clerk-react';
import Image from 'next/image';

import Container from '@/components/Container';

import { underRepair } from '@/constant/config';

import { HeroProps } from '@/types/types';

const Hero: React.FC<HeroProps> = ({ lpConfig, handleUnderRepair }) => {
  return (
    <>
      <Container className='flex flex-wrap'>
        <div className='flex items-center w-full lg:w-1/2 md:pl-6'>
          <div className='max-w-2xl mb-8'>
            <h1 className='text-2xl font-bold leading-snug tracking-tight text-gray-800 sm:text-3xl md:text-4xl lg:leading-tight xl:text-5xl xl:leading-tight 2xl:text-6xl 2xl:leading-tight'>
              {lpConfig.h1Text}
            </h1>
            <p className='py-1 sm:py-3 leading-normal text-gray-500 md:text-xl xl:text-2xl'>
              {lpConfig.pText}
            </p>
            {underRepair ? (
              <div className='flex flex-col items-start py-2 space-y-3 sm:space-x-4 sm:space-y-0 sm:items-center sm:flex-row'>
                <a
                  className='px-4 py-2 md:px-8 md:py-4 text-md sm:text-xl md:text-2xl xl:text-3xl text-center text-black font-bold bg-gold-300 rounded-md border-2 border-black hover:bg-gold-400 transition-colors duration-300 cursor-pointer'
                  onClick={handleUnderRepair}
                >
                  {lpConfig.buttonText}
                </a>
              </div>
            ) : (
              <SignUpButton mode='modal'>
                <div className='flex flex-col items-start py-2 space-y-3 sm:space-x-4 sm:space-y-0 sm:items-center sm:flex-row'>
                  <a className='px-4 py-2 md:px-8 md:py-4 text-md sm:text-xl md:text-2xl xl:text-3xl text-center text-black font-bold bg-gold-300 rounded-md border-2 border-black hover:bg-gold-400 transition-colors duration-300 cursor-pointer'>
                    {lpConfig.buttonText}
                  </a>
                </div>
              </SignUpButton>
            )}
          </div>
        </div>
        <div className='flex items-center justify-center w-full lg:w-1/2 md:pr-6'>
          <div className=''>
            <Image
              src={lpConfig.imgSrc}
              width={1024}
              height={740}
              alt='Hero Illustration'
              placeholder='blur'
              blurDataURL='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg=='
              sizes='(max-width: 1024px) 100vw, 50vw'
              priority
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default Hero;
