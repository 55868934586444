export const faqdata = [
  {
    question: 'How will the illustrations be created?',
    answer:
      'Once you upload your selfies and select the preferred style, our proprietary Generative AI model will automatically create the illustration within a couple of minutes.',
  },
  {
    question: 'Are the sketches available for commercial use?',
    answer:
      'Yes, the generated sketches may be used for both non-commercial and commercial purposes.',
  },
  {
    question: 'Can we ask for minor adjustments on our sketches?',
    answer:
      'Unfortunately not, but feel free to post your request on the contact form.',
  },
];

export const faqdataTShirt = [
  {
    question: 'What is your return policy?',
    answer: `We strive to ensure your complete satisfaction with our products. For Merchandise: We gladly accept returns for any defective products within 30 days of the purchase date. In such cases, we'll provide a full refund and cover the return shipping costs if necessary. Please note that we cannot accept returns or issue refunds for non-defective products. For AI Generation Credits: This digital service is not subject to the cooling-off period stipulated by the Specified Commercial Transactions Act. Your satisfaction is our priority, and we're here to assist you with any concerns about your purchase.`,
  },
  {
    question: 'How long does shipping take?',
    answer: `We make every effort to deliver your custom T-shirts as quickly as possible. Shipping times may vary depending on your location: For customers in the United States: Please allow 2-5 business days for delivery. For international customers: Deliveries typically take approximately 10 business days. Please note that these are estimated timeframes, and we'll provide you with tracking information once your order is shipped.`,
  },
  {
    question: 'What payment methods do you accept?',
    answer: `To ensure secure and convenient transactions, we currently accept credit card payments only. We support all major credit card providers to offer you flexibility in your payment options. Rest assured that all payment information is processed through secure, encrypted channels to protect your personal data.`,
  },
];
