import { lpConfig } from '@/types/types';

export const rootLpConfig: lpConfig = {
  title: 'SketchMe.App',
  description:
    "Create stunning AI-powered profile pictures from your selfies. Stand out on social media with SketchMe.App's instantly generated, unique portraits.",
  url: 'https://sketchme.app',
  h1Text: 'AI Generated Profile Pictures Elevate Your Social Media Presence',
  pText:
    'Using just a few selfies, our Generative AI instantly creates unique illustrations for your social media Profile Picture.',
  buttonText: 'Start Free Trial',
  imgSrc: '/images/hero.png',
};

export const tShirtLpConfig: lpConfig = {
  title: 'AI Custom T-shirt | SketchMe.App',
  description:
    'Get your personalized T-shirts designed by AI in seconds. From pictures of your beloved pets or family members, our AI artist will create unique designs for you.',
  url: 'https://sketchme.app/lp/t-shirt',
  h1Text: 'AI Designed T-shirts for Pet Lovers and Family Lovers',
  pText:
    'Get your personalized T-shirts designed by AI in seconds, from pictures of your beloved ones.',
  buttonText: 'Start Designing for Free',
  imgSrc: '/images/lp/t-shirt/hero3.png',
};

export const profilePictureLpConfig: lpConfig = {
  title: 'AI Profile Picture Generator | SketchMe.App',
  description:
    "Create stunning AI-powered profile pictures from your selfies. Stand out on social media with SketchMe.App's instantly generated, unique portraits.",
  url: 'https://sketchme.app/lp/profile-picture',
  h1Text: 'AI Generated Profile Pictures Elevate Your Social Media Presence',
  pText:
    'Using just a few selfies, our Generative AI instantly creates unique illustrations for your social media Profile Picture.',
  buttonText: 'Start Free Trial',
  imgSrc: '/images/hero.png',
};
